const INITIAL_STATE = {
  toastNoti: '',
}

export function toastReducer(state = INITIAL_STATE, action) {
  switch (action.type) {

    case 'SET_NOTI':
      return {
        ...state,
        toastNoti: action.noti,
      }
    case 'GET_NOTI':
      return {
        ...state,
        toastNoti: action.noti,
      }
    default:
      return state
  }
}
