import React, {useEffect, useRef} from 'react'

// load google map api js
function loadAsyncScript(src) {
}
const apiKey = 'AIzaSyCA5F0pGoVUQo0ZTtUInz6Kd_XfmOW3rAI';
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
 
export const Signout = () => {
  const searchInput = useRef(null);
 
  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if(window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  }

  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    console.log(place)

  }

  // init autocomplete
  const initAutocomplete = () => {
    // if (!searchInput.current) return;
    const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
    autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));

  }

  useEffect(() => {
    initMapScript().then(() => initAutocomplete())
  }, []);



  return (
    <div className="App">
      <div>
        <div className="search">
          <input ref={searchInput} type="text" placeholder="Search location...."/>
        </div>

      </div>
    </div>
  )
}
