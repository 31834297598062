import React from 'react';
import loadingGif from './assets/imgs/loading-gif.gif'
import weblogo from './assets/imgs/weblogo.png'
import { userService } from './services/user/userService'

const SplashScreen = () => {
  const isAuthenticated = userService.getLoginUser();

  return (
    <div className="sign-in-page">
      <div className="div">
        <div className="splash-container">
          <img src={weblogo} alt="App Logo" className="splash-image" />
          {isAuthenticated === null && <img className="loading-gif" src={loadingGif} alt="ok" />}
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
