import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from "react-router-dom";
import { PavingModal } from "../../cmps/paving/PavingModal";
import { GuestModal } from "../../cmps/profile/GuestModal";
import { ReactComponent as Home } from "../../assets/imgs/home-black.svg";
import { ReactComponent as Homeb } from "../../assets/imgs/home-blue.svg";
import { ReactComponent as Mobile } from "../../assets/imgs/mobile.svg";

import { ReactComponent as Search } from "../../assets/imgs/search-black.svg";
import { ReactComponent as Searchb } from "../../assets/imgs/search-blue.svg";
import { ReactComponent as History } from "../../assets/imgs/history-black.svg";
import { ReactComponent as Historyb } from "../../assets/imgs/history-blue.svg";
import { ReactComponent as Settings } from "../../assets/imgs/setting-black.svg";
import { ReactComponent as Settingsb } from "../../assets/imgs/setting-blue.svg";
import { currentLocationAct } from '../../store/actions/userActions'

export const Footer = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { currPage } = useSelector((state) => state.postModule);
  const { loggedInGuest } = useSelector((state) => state.userModule)

  const toggleShowPavingModal = () => {
    setIsShowPavingModal((prev) => !prev);
  };
  const [isShowPavingModal, setIsShowPavingModal] = useState(false);

  const toggleShowGuestModal = () => {
    setIsShowGuestModal((prev) => !prev);
  };
  const [isShowGuestModal, setIsShowGuestModal] = useState(false);

  const showPermissiom = () => {
    try {
      if (navigator.permissions) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            dispatch(currentLocationAct({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            }))
            history.push("/home/map")
          },
          (error) => {
            dispatch(currentLocationAct({
              latitude: 35.6585804,
              longitude: 139.740562
            }))
            history.push("/home/map")
          }
        );
      } else {
        dispatch(currentLocationAct({
          latitude: 35.6585804,
          longitude: 139.740562
        }))
        history.push("/home/map")
      }
    } catch (e) {
      dispatch(currentLocationAct({
        latitude: 35.6585804,
        longitude: 139.740562
      }))
      history.push("/home/map")
    }
  }

  useEffect(() => {
    try {
      navigator.permissions.query({ name: "geolocation" }).then((permissionStatus) => {
        if (permissionStatus.state === "granted") {
          navigator.geolocation.getCurrentPosition(async position => {
            dispatch(currentLocationAct({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            }))
          });
        } else if (permissionStatus.state === "denied") {
          dispatch(currentLocationAct({
            latitude: 35.6585804,
            longitude: 139.740562
          }))
        };
      })
    } catch (e) {
      dispatch(currentLocationAct({
        latitude: 35.6585804,
        longitude: 139.740562
      }))
    }
  }, [dispatch]);

  return (
    <div className="footer">
      <nav className="navf">
        <ul>
          <li
            className={"me-btn " + (currPage === "main" ? "current-btn" : "")}
          >
            <Link to="/home/main">
              <p>
                {currPage !== "main" && (
                  <Home
                    className={
                      "image-icon2 " +
                      (currPage === "main" ? "current-btn" : "")
                    }
                  />
                )}
                {currPage === "main" && (
                  <Homeb
                    className={
                      "image-icon2 " +
                      (currPage === "main" ? "current-btn" : "")
                    }
                  />
                )}
                <span>ホーム</span>
              </p>
            </Link>
          </li>

          <li
            className={
              "me-btn " +
              (currPage === "list" || currPage === "map" || currPage === "listsup" ? "current-btn" : "")
            }
          >
            {/* <Link to={"/home/map"}> */}
            <p>
              {currPage !== "list" && currPage !== "map" && currPage !== "listsup" && (
                <Search
                  onClick={showPermissiom}
                  className={
                    "image-icon2 " +
                    (currPage === "list" ? "current-btn" : "")
                  }
                />
              )}
              {(currPage === "list" || currPage === "map" || currPage === "listsup") && (
                <Searchb
                  onClick={showPermissiom}
                  className={
                    "image-icon2 " +
                    (currPage === "list" ? "current-btn" : "")
                  }
                />
              )}
              <span onClick={showPermissiom}>検索</span>
            </p>
            {/* </Link> */}
          </li>

          <li
            className={"me-btn " + (currPage === "paving" ? "current-btn" : "")}
          >
            {/* <Link to={`/home/paving`}> */}
            <p>
              {currPage !== "paving" && (
                <Mobile
                  onClick={!loggedInGuest ? toggleShowPavingModal : toggleShowGuestModal}
                  className={
                    "image-icon4 " +
                    (currPage === "paving" ? "current-btn" : "")
                  }
                />
              )}
              {currPage === "paving" && (
                <Mobile
                  onClick={!loggedInGuest ? toggleShowPavingModal : toggleShowGuestModal}
                  className={
                    "image-icon4 " +
                    (currPage === "paving" ? "current-btn" : "")
                  }
                />
              )}
              <span
                className={
                  "fab-text " + (currPage === "paving" ? "current-btn" : "")
                }
              >
                東洋舗材
              </span>
            </p>
            {/* </Link> */}
          </li>

          <li
            className={
              "me-btn " + (currPage === "history" ? "current-btn" : "")
            }
          >
            <Link to={`/home/history`}>
              <p>
                {currPage !== "history" && (
                  <History
                    // onClick={loggedInGuest ? toggleShowGuestModal : empty}
                    className={
                      "image-icon2 " +
                      (currPage === "history" ? "current-btn" : "")
                    }
                  />
                )}
                {currPage === "history" && (
                  <Historyb
                    // onClick={loggedInGuest ? toggleShowGuestModal : empty}
                    className={
                      "image-icon2 " +
                      (currPage === "history" ? "current-btn" : "")
                    }
                  />
                )}
                <span>閲覧履歴</span>
              </p>
            </Link>
          </li>

          <li
            className={
              "me-btn " + (currPage === "setting" ? "current-btn" : "")
            }
          >
            <Link to={`/home/setting`}>
              <p>
                {currPage !== "setting" && (
                  <Settings
                    // onClick={loggedInGuest ? toggleShowGuestModal : empty}
                    className={
                      "image-icon2 " +
                      (currPage === "setting" ? "current-btn" : "")
                    }
                  />
                )}
                {currPage === "setting" && (
                  <Settingsb
                    // onClick={loggedInGuest ? toggleShowGuestModal : empty}
                    className={
                      "image-icon2 " +
                      (currPage === "setting" ? "current-btn" : "")
                    }
                  />
                )}
                <span>設定</span>
              </p>
            </Link>
          </li>
        </ul>
      </nav>
      {isShowPavingModal && (
        <PavingModal toggleShowPavingModal={toggleShowPavingModal} />
      )}
      {isShowGuestModal && (
        <GuestModal toggleShowGuestModal={toggleShowGuestModal} />
      )}
    </div>
  );
};
