import { userService } from '../../services/user/userService'
// import { socketService } from '../../services/socket.service'

export function currentLocationAct(current) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'CURRENT_LOCATION', current })
      return current;
    } catch (err) {
      console.log('cannot set current Location:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function getHistories(userId) {
  // template function
  return async (dispatch) => {
    try {
      const his = await userService.searchHistories(userId);
      dispatch({ type: 'GET_HISTORY', his })
      return his;
    } catch (err) {
      console.log('cannot get histories:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function getSupplierById(supId) {
  // template function
  return async (dispatch) => {
    try {
      const supp = await userService.getSupplierById(supId);
      dispatch({ type: 'GET_SUPPLIER', supp })
      return supp;
    } catch (err) {
      console.log('cannot get SupplierById:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function insertHistory(body) {
  return async () => {
    try {
      return userService.insertHistory(body);
    } catch (err) {
      console.log('cannot insert history:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function searchSupplierAct(body) {
  return async (dispatch) => {
    try {
      const supplier = await userService.searchSupplier(body);
      dispatch({ type: 'SEARCH_SUPPLIER', supplier })
      return supplier;
    } catch (err) {
      console.log('cannot search supplier:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function postSupplierAct(body) {
  return async (dispatch) => {
    try {
      const suppliers = await userService.postSupplier(body);
      dispatch({ type: 'POST_SUPPLIER', suppliers })
      return suppliers;
    } catch (err) {
      console.log('cannot post suppliers:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function getNotiAct(body) {
  return async (dispatch) => {
    try {
      const noti = await userService.getNoti(body);
      dispatch({ type: 'GET_NOTI', noti })
      return noti;
    } catch (err) {
      console.log('cannot get Noti:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function login(userCred) {
  return async (dispatch) => {
    try {
      const user = await userService.login(userCred)
      dispatch({ type: 'LOGIN_USER', user })
      // socketService.emit('setUserSocket', user._id)
      return user
    } catch (err) {
      console.log("can't do login:", err)
      dispatch({ type: 'SET_NOTI', noti: 'Error login'})
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function setLoginUser() {
  return async (dispatch) => {
    try {
      const user = await userService.getLoginUser()
      dispatch({ type: 'LOGIN_USER', user })
      return user
    } catch (err) {
      console.log('cannot getLoginUser:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function getCreatedinUser(email) {
  return async (dispatch) => {
    try {
      const user1 = await userService.getCreatedinUser(email)
      dispatch({ type: 'CREATED_IN_USER', user1 })
      return user1
    } catch (err) {
      console.log('cannot getCreatedinUser:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function deactivedUserAct(userId) {
  return async () => {
    try {
      return await userService.deactivedUser(userId)
    } catch (err) {
      console.log('cannot deactived user:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function updateUserAct(userId, userCred) {
  return async (dispatch) => {
    try {
      const userup = await userService.updateUser(userId, userCred)
      dispatch({ type: 'UPDATE_USER', userup })
      return userup
    } catch (err) {
      console.log('cannot update user:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function signup(userCred) {
  return async (dispatch) => {
    try {
      const user1 = await userService.signup(userCred)
      dispatch({ type: 'SIGNUP', user1 })
      // dispatch({ type: 'SET_NOTI', noti: ''})
      return user1
    } catch (err) {
      console.log('cannot signup:', err)
      dispatch({ type: 'SET_NOTI', noti: '登録中にエラーが発生しました'})
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function logout() {
  return async (dispatch) => {
    try {
      return await userService.logout()
    } catch (err) {
      console.log('cannot logout:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function clearEmail() {
  return async () => {
    try {
      return await userService.clearEmail()
    } catch (err) {
      console.log('cannot clearEmail:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function setLoginGuest(bool) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'LOGGEDIN_GUEST', bool })
    } catch (err) {
      console.log('cannot login guest:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function sendToEmail(emailCred, type) {
  return async (dispatch) => {
    try {
      return await userService.sendToEmail(emailCred, type)
    } catch (err) {
      console.log("can't do sent email:", err)
      dispatch({ type: 'SET_NOTI', noti: 'メール送信に失敗しました'})
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function bigCategory() {
  return async (dispatch) => {
    try {
      const bigc = await userService.bigCategory();
      dispatch({ type: 'BIG_CATEGORY', bigc })
      return bigc;
    } catch (err) {
      console.log('cannot get bigCategory:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function mediumCategory(bigId) {
  return async () => {
    try {
      return await userService.mediumCategory(bigId);
    } catch (err) {
      console.log('cannot get mediumCategory:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function detailCategory(bigId, medId) {
  return async () => {
    try {
      return await userService.detailCategory(bigId, medId);
    } catch (err) {
      console.log('cannot get detailCategory:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}