import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toastService } from '../services/toastService';
import logow from "../assets/imgs/weblogo.png";
import warning from "../assets/imgs/warning.png";

import { login, setLoginGuest, clearEmail, logout } from '../store/actions/userActions'

export const Signin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [cred, setCred] = useState({
    email: "",
    password: "",
  });
  const [msgEmail, setMsgEmail] = useState("");
  const [msgPass, setMsgPass] = useState("");
  const [msg, setMsg] = useState("");
  const [label, setLabel] = useState(true);

  const showMsgEmail = (txt) => {
    setMsgEmail(txt);
    setLabel(false);
    setTimeout(() => setMsgEmail(""), 3000);
    setTimeout(() => setLabel(true), 3000);
  };

  const showMsgPass = (txt) => {
    setMsgPass(txt);
    setLabel(false);
    setTimeout(() => setMsgPass(""), 3000);
    setTimeout(() => setLabel(true), 3000);
  };

  const showMsg = (txt) => {
    setMsg(txt);
    setLabel(false);
    setTimeout(() => setMsg(""), 3000);
    setTimeout(() => setLabel(true), 3000);
  };
  const handleEmailChange = async ({ target }) => {
    const field = target.name;
    let value = target.type === "number" ? +target.value || "" : target.value;
    setCred((prevCred) => ({ ...prevCred, [field]: value }));
  };

  const handlePassChange = async ({ target }) => {
    const field = target.name;
    let value = target.type === "number" ? +target.value || "" : target.value;
    setCred((prevCred) => ({ ...prevCred, [field]: value }));
  };

  const doLogin = async () => {
    if (cred.email !== "" && cred.password !== "") {
      if (!/.+@.+/.test(cred.email)) {
        showMsgEmail('メールアドレスが無効です')
      }
      // if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,40}/.test(cred.password)) {
      //   showMsgPass('半角英数字、記号の組み合わせ8文字以上')
      // } else if (/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(cred.email) && /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,40}/.test(cred.password)) {
      dispatch(login(cred)).then((user) => {
        if (user !== null && user.status === 500) {
          toastService.notifyError('ネットワークエラー');
          history.push("/signin");
        } else if (user !== null && user.status === 400) {
          showMsg(user && user.message)
        } else if (user !== null && user.status === 404) {
          showMsg(user && user.message)
        } else if (user !== null && user.status === 200) {
          history.push("/home/main");
        }
      });
      // }
    } else {
      if (cred.email === "") {
        showMsgEmail('メールアドレスを入力してください')
      }
      if (cred.password === "") {
        showMsgPass('パスワードを入力してください')
      }
    }
  };

  const doLoginGuest = async () => {
    dispatch(setLoginGuest(true))
      .then(() => {
        history.push("/home/main");
      })
      .catch((err) => {
        showMsg("入力した情報に誤りがあります");
      });
  };

  useEffect(() => {
    dispatch(setLoginGuest(false));
    dispatch(clearEmail());
    dispatch(logout());
  }, [dispatch])

  return (
    <div className="sign-in-page">
      <ToastContainer />
      <div className="div">
        <div className="div-2">
          <div className="div-5"><img className="logow" src={logow} alt="Toyohozai" /></div>
        </div>
        {/* <div className={(!cred.email && !cred.password) ? 'divv-6' : 'div-6'}> */}
        <div className='divv-6'>
          <div className="div-7">サインイン</div>
          <form>
            <div className="but-8"><p onClick={(ev) => {
              ev.preventDefault();
              doLoginGuest();
            }}>会員登録せずに利用する</p></div>
          </form>
          <div className="div-90">
            <div className="div-91" />
            <div className="div-92">または</div>
            <div className="div-91" />
          </div>

          <form
            onSubmit={(ev) => {
              ev.preventDefault();
              doLogin();
            }}
          >
            <div className="div-8">
              <div className="div-9">ID</div>
              <input
                className={"div-10 " + (msg !== '' ? 'invalid' : '')}
                // pattern="/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/"
                onChange={handleEmailChange}
                type="text"
                id="email"
                name="email"
                value={cred.email}
                placeholder="メールアドレス"
              />
              <div className="msg">{msgEmail} </div>
              <div className="div-9">パスワード</div>
              <input
                className={"div-10 " + (msg !== '' ? 'invalid' : '')}
                // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                onChange={handlePassChange}
                type="password"
                id="password"
                name="password"
                value={cred.password}
                placeholder="●●●●●●●●"
              />
            </div>
            <div className="msg">{msgPass} </div>
            <div className="msg">
              {msg !== '' && <img src={warning} alt="Toyohozai" className="img-msg" />}
              {msg !== '' && <div className="div-msg">{msg} </div>}
            </div>
            <div className="label1">※推奨環境: Google Chrome（Edgesだと不具合出る場合があります）</div>

            <button className="sign-in-btn">サインイン</button>
          </form>
          <div className="div-7">東洋舗材 (株)</div>
          <div className="div-18">
            パスワードをお忘れの方は
            <Link to="/forgot">
              <span
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                こちら
              </span>
            </Link>
            <br />
            アカウントをお持ちでない方は
            <Link to="/signup">
              <span
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                会員登録
              </span>
            </Link>
            <br />
            <br />
            <span>新規登録後、審査をさせていただきます。</span>
            <br />
            <span>審査完了までお時間をいただく場合がございます。</span>
            <br />
            <span>ご了承ください。</span>
          </div>
        </div>
      </div>
    </div>
  );
};
