import { useEffect } from 'react'
import cross from "../../assets/imgs/cross.png";

export function TearmModal({ toggleShowTearmModal }) {


  useEffect(() => { }, [])

  return (
    <section className="tearms">
      <div className="bg" onClick={toggleShowTearmModal}></div>
      <div className="container">
        <div className="x-title">
          <span onClick={toggleShowTearmModal}>
            <img src={cross} alt="Toyohozai" className="title-icon" />
          </span>
        </div>
        <div className="title">
          <h1>個人情報の取り扱い</h1>
        </div>
        <div className="txt"><p>個人情報の取り扱い、及び、利用規約</p></div>
        <div className="form-container">
          <div className='form-title'>第１条 （本規約について）</div>
          <ol class="pg-rules-listnumber">
            <li>本規約は、東洋補材株式会社（以下「当社」という。）が提供する、ウェブサイト、公式アプリその他のアプリケーション、ソーシャルネットワーク機能及び物品販売その他一切のサービス（以下、これらを併せて「本サービス」という。）を利用する本サービスの利用を希望する者（以下「利用者」という。）が、本サービスを利用するにあたっての条件を定めたものです</li>
            <li>本規約は、利用者が本サービスを利用するあたり、その利用に関する一切の行為に適用されます</li>
            <li>利用者は、本規約に従い本サービスを利用するものとし、利用者の不快、有害、差別的、欺瞞的又は不適切なものと当社が判断する内容の投稿、その他本規約第15条に規定する禁止事項に該当する内容の投稿について、利用者に対し、当社が、本規約に基づいて、以下に定める処置を行うことに関し、同意します</li>
            <li>利用者が、本サービスを利用した場合には本規約の全ての記載内容について同意したものとみなします</li>
            <li>当社が別途規定する個別規定及び当社が随時追加する規定は、本ウェブサイトに掲載した時に、本規約の一部を構成するものとします。本規約と個別規定又は追加規定が異なる場合には、個別規定又は追加規定が優先して適用されるものとします</li>
          </ol>
          <div className='form-title'>第２条 （定義） 本規約上で使用する用語の定義は、次の通りとします</div>
          <ol class="pg-rules-listnumber">
            <li>本サービス　当社が運営するウェブサイト、公式アプリその他のアプリケーション、ソーシャルネットワーク機能及び物品販売その他一切のサービス</li>
            <li>本ウェブサイト　本サービスの当社コンテンツが掲載されたウェブサイト</li>
            <li>当社コンテンツ　当社が本ウェブサイトにて提供する情報・画像等の総称（投稿情報を含みます）</li>
            <li>利用者　本サービスを契約されている個人又は法人及びそれらの契約者の契約に基づき本サービスを利用される方すべて。</li>
            <li>ＩＤ　本サービスの利用のために利用者が固有に持つ文字列</li>
            <li>パスワード　ＩＤに対応して利用者が固有に設定する暗号</li>
            <li>投稿　利用者が文章、文字列、画像、動画、コメント等を掲載する行為</li>
            <li>投稿情報　利用者が投稿等により掲載した文章、文字列、画像、動画、コメント等の情報の総称</li>
            <li>個人情報　住所、氏名、職業、電話番号等個人を特定することのできる情報の総称</li>
            <li>登録情報　利用者が作成したアプリ内に記載された情報の総称</li>
            <li>（８）と同じではないか？削除するか（１１）を採用するか？
              投稿情報　投稿情報及び利用者自ら著作権等の必要な知的財産権を有するか、又は必要な権利者の許諾を得て投稿した動画、画像、文章その他のコンテンツ
              外部サービス　利用者が利用する当社以外の第三者が提供するウェブサイト、サードパーティ・アプリ、サービス等をいいます。</li>
            <li>本サービス利用権　利用者が本サービスを利用することに関するすべての権利</li>
            <li>知的財産　発明、考案、植物の新品種、意匠、著作物その他の人間の創造的活動により生み出されるもの（発見又は解明がされた自然の法則又は現象であって、産業上の利用可能性があるものを含む）、商標、商号その他事業活動に用いられる商品又は役務を表示するもの及び営業秘密その他の事業活動に有用な技術上又は営業上の情報</li>
            <li>知的財産権　特許権、実用新案権、育成者権、意匠権、著作権、商標権その他の知的財産に関して法令により定められた権利又は法律上保護される利益に係る権利</li>
          </ol>

          <div className='form-title'>第３条 （本規約の変更）</div>
          <ol class="pg-rules-listnumber">
            <li>当社は、利用者の承諾を得ることなく、本規約を変更できるものとします。</li>
            <li>変更後の利用規約は、当社が別途定める場合を除き、本ウェブサイト上に表示した時点から効力を生じるものとします。</li>
            <li>利用者が、本規約の変更後に本サービスを利用する場合、当該変更後の全ての記載内容に同意したとみなし、変更後の利用規約が適用されます。</li>
            <li>当社は、本サービスの利用料の変更を行うことができるものとし、本サービス上に表示した時点から、その変更は直ちに適用されます。</li>
          </ol>
          <div className='form-title'>第４条 （個人情報の取り扱い）</div>
          <ol class="pg-rules-listnumber">
            <li>当社は、利用者の個人情報を、当社が定めるプライバシーポリシーに基づき、適切に取り扱います。</li>
            <li>プライバシーポリシーは、本規約と一体となります。</li>
          </ol>
          <div className='form-title'>第５条 （サービスの運営）</div>
          <ol class="pg-rules-listnumber">
            <li>当社は、本サービスの運営に関し、完全かつ独自の裁量権を有しているものとします。</li>
            <li>当社は、本サービスの運営上で必要と思われるその他の一切の処置を任意に行う権限を有しているものとします。</li>
            <li>利用者は、当社が行う一切の処置に関して、何らの請求権を取得することもないものとします。</li>
          </ol>
          <div className='form-title'>第６条 （本サービスの利用）</div>
          <ol class="pg-rules-listnumber">
            <li>本サービスの利用を希望する者は、本規約の内容に同意した上で、当社所定の手続きに従って本サービスのアカウントを作成し、本サービス利用の申込を行うものとします。</li>
            <li>本サービスの利用を希望する者は、アカウント作成に際して登録する情報に誤りがないよう十分注意し、常に正しい情報を登録しなければなりません。</li>
            <li>本サービス利用の申込をした者は、当社が申込を承諾した時点で利用者になります。</li>
            <li>本サービスの利用を希望する者は、アカウント作成に際して登録した内容に変更が生じた場合、当社所定の手続に従って、速やかに登録内容変更の手続を行うものとします。</li>
            <li>本サービスの利用を希望する者が登録した情報に誤りがあったため又は本サービスの利用を希望する者が前項に定める手続を怠ったために、当社からの通知が延着又は不到達となった場合、当該通知は通常到達すべきときに到達したものとみなします。</li>
            <li>本サービスの利用を希望する者は、アカウント登録に際して登録した情報（登録名、ＩＤ、パスワード及び本サービスの利用を希望する者の情報を含みますが、これらに限りません。）を善良なる管理者の注意をもって管理するものとします。</li>
            <li>本サービス利用権は、アカウントが削除された時点で消滅します。</li>
            <li>本サービスのアカウント及び本サービス利用権は、本サービスの利用を希望する者に一身専属的に帰属し、本サービスの利用を希望する者は、本サービス利用権を第三者に譲渡、貸与その他の処分又は相続させることができません。</li>
            <li>利用者は、当社が、本サービスの運営・提供に際し、利用者に対して新サービスの告知、広告配信、サービス運営上の事務連絡、その他情報の提供を行うことを予め了承するものとします。ただし、利用者が情報の提供を希望しない旨を、事前又は事後に当社所定の方法で通知した場合は、本サービスの提供に必要な場合を除いて、情報の提供を行わないものとします。</li>
            <li> 当社は、利用者の利用登録に際し、利用者について、以下の事由があると判断した場合は、当該利用登録を認めず、利用者は、登録を申請した名前、メールアドレス又はＩＤを利用できない場合があります。
              <ol class="pg-rules-listnumber">
                <li>当社が定める方法に従い、本サービス利用の申込を行わない場合</li>
                <li>本サービスの利用申込者が、過去に本規約又はその他の規約等に違反したことを理由として強制退会処分を受けた者である場合</li>
                <li>他人になりすましていると考えられる場合</li>
                <li>他人が登録する氏名を用いる場合</li>
                <li>他人の知的財産権その他の権利が侵害されるおそれがある場合</li>
                <li>他人に嫌悪感を与えるおそれがあると当社が判断した場合</li>
                <li>本サービスの利用申込者が反社会的勢力又はそれに準ずるものに該当する場合</li>
                <li>本サービスの利用申込者が第13条（１１条ではないか？）で禁止している事項を行うおそれがある場合</li>
                <li>その他、当社が利用者の登録が著しく不適切と判断した場合</li>
              </ol>
            </li>
            <li>利用者は、利用者自身の自己責任において本サービスを利用するものとし、本サービスを利用してなされた一切の行為及びその結果について一切の責任を負います。</li>
            <li>本サービスを利用して利用者が投稿した記事、投稿又は送信した文章、動画、画像、当社コンテンツ、素材、投稿その他の情報に関する責任は、利用者自身にあります。</li>
            <li>当社は、利用者が本サービスを利用して投稿した投稿情報等の情報の内容について、一切責任を負いません。</li>
            <li>利用者が他人の名誉を毀損した場合、プライバシー権を侵害した場合、許諾なく第三者の個人情報を開示した場合、著作権法に違反する行為を行った場合、その他他人の権利を侵害した場合には、当該利用者は自身の責任と費用において解決しなければならず、当社は一切の責任を負いません。</li>
            <li>利用者は、利用者が本サービスを利用して投稿した投稿情報等について当社に保存義務がないことを認識し、必要な情報については自己の責任をもって保存するものとします。</li>
          </ol>
          <div className='form-title'>第７条 （利用者による管理）</div>
          <ol class="pg-rules-listnumber">
            <li>利用者は、一人につき一つのアカウントのみを保有するものとします。</li>
            <li>一人が複数のアカウントを保有すること及び第三者の代わりに利用登録をすることは禁止されています。</li>
            <li>利用者は、利用登録にあたり、当社が入力を求める事項を入力するものとします。</li>
            <li>利用者は、本サービスを利用するパソコン、タブレット、携帯電話及びスマートフォン等の端末機器並びにメールアドレスやパスワードを、自らの責任で管理するものとします。</li>
            <li>利用者は、自己の登録メールアドレス、ＩＤ及びパスワードの不正利用の防止に努めるとともに、その管理について一切の責任を持つものとします。</li>
            <li>利用者が登録したメールアドレスやＩＤ、パスワードが第三者に利用されたことによって生じた損害等につきましては、当社はいかなる責任を負いません。</li>
            <li>利用者は、当社の許諾がない限り、利用者名、ＩＤ、パスワード又は関連するアカウント情報を開示、貸与、売買、贈与又は譲渡等できないものとします。</li>
            <li>利用者は、本サービスにアクセスするために使用するＩＤ、パスワードを自己の責任において、管理するものとします。当社は登録されたＩＤ、パスワードによる本サービスの利用があった場合、利用登録を行った本人が利用したものと扱うことができ、当該利用によって生じた一切の責任は、利用登録を行った本人に帰属するものとします。</li>
            <li>利用者は、自己の登録名、ＩＤ又はパスワードの不正使用を発見したときは、直ちに当社に通知するものとします。その場合、当社は、これを是正するための必要な努力をするものとします。</li>
          </ol>
          <div className='form-title'>第８条 （本サービスで利用される情報）</div>
          <ol class="pg-rules-listnumber">
            <li>登録情報は、以下のとおり本サービスで利用されます。なお、登録情報のメールアドレス認証のため、認証メールが送信されます。
              <ol class="pg-rules-listnumber">
                <li>本サービスの提供のため </li>
                <li>本サービスのシステムの構築、改良、メンテナンスのため</li>
                <li>お問い合わせやご依頼に対応するため</li>
                <li>本サービスの利用状況、利用環境を含む顧客満足度調査のため</li>
                <li>当社又は企業利用者より、広告、求人情報、サービスをご案内するため</li>
                <li>その他各種ご案内やご連絡のため </li>
              </ol>
            </li>
            <li>利用者の利用履歴等は、以下のとおり本サービスで利用されます。なお、当社は、利用者が本サービスを利用した履歴、利用環境、特定機能の利用状況等について、アプリケーションを通じて取得します。
              <ol class="pg-rules-listnumber">
                <li>本サービス提供のため</li>
                <li>本サービスのシステムの構築、改良、メンテナンスのため</li>
                <li>本サービスの利用状況、利用環境を含む顧客満足度調査のため</li>
                <li>当社又は当社の提携先より、広告、求人情報、サービスをご案内するため　</li>
                <li>利用者からのお問い合わせやご依頼に対応するため</li>
                <li>その他各種ご案内やご連絡のため </li>
              </ol>
            </li>
            <li>利用者の投稿には、以下の条件が適用されます。
              <ol class="pg-rules-listnumber">
                <li>利用者は、本サービスで他の利用者に閲覧されることに予め同意の上で投稿その他の方法により投稿情報を送信するものとします。</li>
                <li>利用者は、投稿情報について自らが投稿等することについての適法な権利を有していること、及び第三者の知的財産権等、アイデア、ノウハウ、所有権、プライバシーその他の権利を侵害していないことについて、当社に対し表明し、保証するものとします。</li>
                <li>本サービスにおいて、投稿情報に関する著作権は、当該投稿等を行った利用者自身に留保されるものとします。ただし、利用者は、本サービスの提供、維持、改善又は本サービスのプロモーションに必要な範囲内で、当社による投稿情報の複製、翻案、公衆送信（公衆への送信を可能とすることを含む）等の無償利用及び利用者が本サービスを退会した後、相当期間、当社が利用することに同意するものとします。　</li>
                <li>利用者は、当社及び当社から権利を承継し又は許諾された者に対し、著作者人格権を行使しないことを予め同意します。 </li>
              </ol>
            </li>
          </ol>

          <div className='form-title'>第９条 （利用環境）</div>
          <ol class="pg-rules-listnumber">
            <li>利用者は、本サービスを利用するために必要なあらゆる機器、ソフトウェア、通信手段を自己の責任と費用において、適切に整備するものとします。 </li>
            <li>利用者は、自己の利用環境に応じて、コンピューターウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を講じるものとします。 </li>
            <li>当社は、利用者の利用環境について一切関与せず、また一切の責任を負いません。</li>
          </ol>
          <div className='form-title'>第10条 （権利関係）</div>
          <ol class="pg-rules-listnumber">
            <li>利用者が投稿又は編集し、利用者自ら著作権等の必要な知的財産権を有する場合、又は必要な権利者の許諾を得た場合における動画、画像、文章その他のコンテンツを除き、本サービス及び本サービスに関連する一切の情報についての著作権及びその他の知的財産権はすべて当社又は当社にその利用を許諾した権利者に帰属します</li>
            <li>当社の作成したコンテンツは、著作権、商標権、特許権その他の権利に関する法律に基づき保護されています。</li>
            <li>利用者は、当社の作成したコンテンツに含まれているか、又は付帯しているいかなる著作権のある表示、商標、サービスマークなどの専有権のある表示も削除、改変、又は隠蔽せず、当社の作成したコンテンツについて、再現、修正、改造、派生著作物の作成、公演、表示、公開、配布、伝送、放送、販売、利用許諾、営利利用を行わないものとします。</li>
            <li>利用者は、投稿情報のみ、本サービスを利用して、投稿又は編集することができるものとします。</li>
            <li>利用者は、当社に対し、利用者が投稿する投稿情報に関して、これを使用する非専属的、譲渡可能、再許諾権付き、かつ無償の世界的使用許諾の権限を当社に付与するものとします。当該許諾は、投稿情報が他の人と共有され、その人が投稿情報を削除した場合であっても、依然として有効とするものとします。</li>
            <li>利用者又は投稿情報の権利者は、投稿情報の著作権を含む知的財産権を従前通り保持するものとします。ただし、当社は、投稿情報を、本サービスの提供を目的とする範囲内において、当社が必要と判断する処理・手続きを行った上で、当社及び当社と提携するサイト等において利用できるものとします。利用者は、本項に基づく当社の利用について、著作者人格権を行使しないものとします。</li>
            <li>利用者は、本利用規約及びその他の利用規約等に基づき、本サービスに関する使用権のみを取得し、本サービスに関する所有権、知的財産権等その他のいかなる権利も取得しないものとします。</li>
            <li>当社は、利用者からフィードバックを取得する場合があります。利用者は、フィードバックの内容につき、当社が無償利用すること及び利用者が本サービスを退会した後も（相当期間）当社が利用することに同意するものとします。なお、当社は、フィードバックの内容に拘束される義務はありません。</li>
            <li>本条の規定に違反して紛争が発生した場合、利用者は、自己の責任と費用をもって当該紛争を解決するとともに、当社をいかなる場合においても免責し、当社に損害を与えないものとします。</li>
          </ol>
          <div className='form-title'>第11条 （禁止事項）</div>
          <ol class="pg-rules-listnumber">
            <li>
              利用者は、本サービスの利用にあたって、以下の行為を行ってはなりません。本条が定める禁止事項に違反した場合には、当社が、強制退会、利用停止、投稿情報等の情報の全部若しくは一部の削除等の不利益な措置を採ることがあります。
              <ol class="pg-rules-listnumber">
                <li>当社、他の利用者又は第三者の著作権、商標権その他の知的財産権を侵害し、若しくは侵害するおそれのある行為。</li>
                <li>当社、他の利用者又は第三者の財産、プライバシー又は肖像権を侵害し、若しくは侵害するおそれのある行為。</li>
                <li>当社、他の利用者又は第三者に不利益又は権利に損害を与え、若しくは与えるおそれのある行為。</li>
                <li>当社若しくは他者を不当に差別若しくは誹謗中傷し、他者への不当な差別を助長し、又はその名誉若しくは信用を毀損する行為。</li>
                <li>利用者本人以外の人物を名乗ったり、代表権や代理権がないにもかかわらずあるものと装ったり、又は他の人物や組織と提携、協力関係にあると偽って本サービスを利用する行為。</li>
                <li>詐欺、規制薬物の濫用、児童売買春、預貯金口座及び携帯電話の違法な売買等の犯罪に結びつく、又は結びつくおそれのある行為。</li>
                <li>違法な賭博・ギャンブルを行わせ、又は違法な賭博・ギャンブルへの参加を勧誘する行為。</li>
                <li>違法行為を請け負い、仲介し、又は誘引する行為。</li>
                <li>他人を自殺に誘引又は勧誘する行為。</li>
                <li>わいせつ、児童ポルノ又は児童虐待に相当する情報を投稿、送信する行為。</li>
                <li>利用者本人又は第三者が行う事業若しくはネットワークビジネス等の営業行為、政治団体、宗教団体への勧誘その他、他の会員に対して本件サービスとは無関係の何らかの営業行為や勧誘行為。</li>
                <li>利用者としての権利、立場を、他の会員、第三者等に譲渡、承継、行使させ又は担保に供する等の行為。</li>
                <li>通信販売、連鎖販売取引及び業務提供誘引販売取引等に利用する目的をもって他の会員の情報等を収集、使用又は開示する行為。</li>
                <li>本サービスの一部又は全部の複製・改変・編集・消去・他のプログラム等との結合・リバースエンジニアリング・逆アセンブル・逆コンパイル等又はミラーサイト構築等の行為</li>
                <li>スクレイピング、クローリング（クローラ、ロボット及びスパイダー等のプログラム）及びその他の類似の手段によって本サービスにアクセスし、又は登録された情報を含む本サービスに関する情報を取得する行為 </li>
                <li>本サービスに関連して、以下について投稿、送信、又は利用可能にする行為
                  以下の項目がわかりにくいので、項目を整理する必要があります。
                  <ol class="pg-rules-listnumber">
                    <li>違法行為又はハッキング、クラッキング、フリーキングといった行為を助長又はその方法を紹介する情報を提供するもの</li>
                    <li>ウイルス、ワーム、トロイの木馬、イースターエッグ、スパイウェアその他の以下のようなコンピューターコード、ファイル又はプログラム</li>
                    <li>潜在的に有害又は侵略性のあるもの</li>
                    <li>ハードウェア、ソフトウェアその他の機器の操作に損害を与え若しくは乗っ取ることのできる、又はこれを意図するもの</li>
                    <li>ハードウェア、ソフトウェアその他の機器の使用を監視することのできる、又はこれを意図するもの</li>
                    <li>未承諾広告、宣伝資料、ジャンクメール、スパム、チェーンレター、マルチ商法若しくは投資機会その他勧誘 ・適切な許諾を得ていない、企業の非公開情報又はその資料</li>
                    <li>本サービスの利用に関し、自ら又は第三者のために不正な利益を得ようとする行為。この行為には、不正行為、バグ、自動ソフトウェア、ボット、ハック、無断改変（モッズ）その他の許可されていない第三者のソフトウェアを使用し、又は本サービスのバグやエラーを探すことによって行うことを含みます。</li>
                    <li>不正行為を行うソフトウェア、自動ソフトウェア、ボット、ハック、無断改変（モッズ）その他の許可されていない第三者のソフトウェアを作成する又は作成を助ける意図で本サービスを利用する行為</li>
                    <li>本サービス又は本サービスを提供するためのサーバーやネットワークの運営を妨害する行為。この行為には不合理又は不均衡に大きな負荷を本サービス、本サービスのためのサーバー若しくはネットワークにかけること、又はこれらのネットワークの要件、手続き、ポリシー、規則に違反することを含みます。</li>
                    <li>ハッキングや本サービスの一部を書き換えること等により、第三者が本サービスを利用することを制限又は阻害する行為 ・本サービスのいかなる部分においても、修正、改変、翻案の創作、翻訳、リバースエンジニアリング、逆コンパイル又は逆アセンブルをする行為 ・当社の事前の書面による承諾なしに、本サービスのいかなる部分であれフレーム又はミラーを作成する行為</li>
                    <li>本サービス上で又は本サービスを経由してシステムにより情報及び素材をダウンロードし、保持することによってデータベースを作成する行為</li>
                    <li>当社の事前の書面による承諾なしに、本サービス上で若しくは本サービスを経由して利用可能な情報及び素材を取り出し、インデックスを作成し、抽出（スクレイプ）し、データマイニングその他の方法で収集するため、又は本サービスのナビゲーション構造若しくは表示を回避し又は複製するために、ロボット、スパイダー、サイト検索・解析アプリケーションその他手動又は自動の装置を利用する行為</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>利用者は、前に掲げる禁止事項に該当する行為によって当社、他の利用者又は第三者に損害が生じた場合、利用者資格を喪失した後であっても、全ての法的責任を負うとともに自ら解決するとともに、当社に一切の責任を負わせず、費用を負担させないものとします。 </li>
          </ol>
          <div className='form-title'>第12条 （第三者のサービス）</div>
          <ol class="pg-rules-listnumber">
            <li>x本サービスにおいて、第三者のウェブサイト、サードパーティ・アプリ、サービス等（以下、これらを併せて「外部サービス」といいます。）へのリンクを提示されることがあります。利用者が外部サービスを利用する場合、利用者は、当該第三者の利用規約及びプライバシーポリシーに従い、自らの責任でこれを利用するものとし、他の利用者が利用する場合があることについても認めるものとします。</li>
            <li>xこれらの外部サービス又は第三者の作為若しくは不作為については、これを提供する第三者たる事業者が責任を負い、当社は一切の責任を負担しません。</li>
            <li>x第三者のウェブサイト等が、当社の承諾の有無を問わず、本サービスへのリンクを提供することがあります。当社は、これら第三者のウェブサイトに関連して利用者が被った損害について一切の責任を負いません。</li>
          </ol>
          <div className='form-title'>第13条 （本サービスの中止・中断・変更）</div>
          <ol class="pg-rules-listnumber">
            <li>当社は、以下の事項に該当する場合、利用者に通知することなく、本サービスを変更し、運営を停止又は中止できるものとします。
              <ol class="pg-rules-listnumber">
                <li>本サービスに使用するシステムの保守を定期的に又は緊急に行う場合。</li>
                <li>戦争、暴動、騒乱、労働争議、地震、火災、津波、停電その他の非常事態により、本サービスの提供が通常どおりできなくなった場合。</li>
                <li>本サービスの提供が技術的に困難又は不可能となった場合。</li>
                <li>当社が、本サービスの運営上、必要と判断した場合。 </li>
              </ol>
            </li>
            <li>当社は、本サービスの変更、停止又は中止等により、利用者又は第三者が被ったいかなる損害についても責任を負わないものとします。 </li>
          </ol>
          <div className='form-title'>第14条 （免責事項）</div>
          <ol class="pg-rules-listnumber">
            <li>本サービスは現状有姿で提供されるものとし、無欠陥や無停止やデータの完全性やセキュリティの万全性等に関して保証をしたり、利用者の期待する機能や、利用者のビジネスへの合致などを保証したり約束することはありません。 </li>
            <li>当社は、利用者が本サービスを利用して提示、投稿又は送信した動画、画像、コンテンツ、素材、投稿その他の情報等を、本サービスを遂行するため又は本規約の遵守状況を確認するために必要な範囲で閲覧することができます。 </li>
            <li>当社は、以下に定める事由に該当する場合には、利用者に対する事前の通知を行うことなく、当該投稿情報等を削除し、又は投稿情報等を投稿した利用者又はその他の利用者が当該投稿情報等を閲覧できないようにする措置を採ることができるものとします。なお、利用者は、当該措置に関して、当社に対して何らの異議申し立て、請求等を行えないものとします。
              <ol class="pg-rules-listnumber">
                <li>当該利用者が退会した場合。</li>
                <li>第15条が定める禁止事項に該当する行為があると判断した場合。</li>
                <li>運営・保守管理上、必要であると判断した場合。</li>
                <li>利用者が登録した情報等に誤りがあると判断した場合。</li>
                <li>投稿情報等が本規約に抵触する場合</li>
                <li>その他、上記に類する事態が発生し、当社が必要と判断した場合。 </li>
              </ol>
            </li>
            <li>当社は、本サービス又は当社のその他の権利及び資産、又は第三者の生命、身体若しくは財産を保護するために必要と判断した場合、必要な範囲内において、これらの本投稿等を裁判所や警察等の公的機関に開示又は提供できるものとします。 </li>
            <li>当社は、利用者の通信や活動に関与しません。万一利用者間の紛争があった場合でも、当該利用者間で解決するものとし、当社はその責任を負いません。 </li>
            <li>当社は、利用者による本サービスの利用に関し、本サービス提供の全部ないし一部の中断、データの損失、データの改ざん、不正アクセス、コンピュータウイルス、情報漏洩等を含む一切の不具合・契約不適合・事故・事件・履行不能・履行遅滞が発生した場合においても、利用者に発生した一切の損害について責任を負いません。 </li>
            <li>当社は、本サービスの内容の追加、変更、又は本サービスの中断、終了によって生じたいかなる損害についても、一切責任を負いません。アクセス過多、その他予期せぬ要因で表示速度の低下や障害等が生じた場合も同様とします。 </li>
            <li>当社は、利用者によって投稿される投稿情報等の情報を監視または保存する義務を負いません。 </li>
            <li>当社は、利用者によって投稿される投稿情報等の情報の合法性、道徳性、信頼性、正確性について一切の責任を負いません。 </li>
            <li>当社は、次に掲げる場合には、投稿情報等の情報の内容を閲覧したり、保存したり、第 三者に開示することができるものとします。当社は、それによって生じたいかなる損害についても、一切責任を負いません。
              <ol class="pg-rules-listnumber">
                <li>本サービスの技術的不具合の原因を解明し、解消するため必要な場合。</li>
                <li>行政機関又は裁判所等から、法令に基づく正式な照会を受けた場合。</li>
                <li>本規約に違反する行為又はそのおそれのある行為が行われ、利用者の情報の内容を確認する必要が生じたと当社が判断した場合。 </li>
                <li>人の生命、身体及び財産などに差し迫った危険があり、緊急の必要性があると当社が  判断した場合。 </li>
                <li>その他本ウェブサイトを適切に運営するために必要不可欠な事態が生じた場合。</li>
              </ol>
            </li>
            <li>当社は、本規約又はその他の規約等に違反する行為又はそのおそれのある行為が行われたと判断した場合には、当該行為を行った利用者の強制退会処分、投稿情報等の情報の全部若しくは一部の削除、及び公開範囲の変更等を行う場合がありますが、それによって生じたいかなる損害についても、一切責任を負いません。 </li>
            <li>当社が利用者に対してサポートを行う場合、サポートの内容及び当該サポートに起因して発生した一切の損害について責任を負いません。 </li>
            <li>当社は、利用者が本サービスの利用に関して損害を負った場合、当社の債務不履行責任、不法行為責任その他法律上の責任を問わず、一切賠償の責任を負いません。ただし、当社に故意又は重過失がある場合、当社は、当該利用者が直接かつ現実に被った損害に限り、損害賠償責任を負うものとし、かつ、損害の事由が生じた時点から遡って過去１年間に当該利用者から受領した利用料金の総額を上限とします。 </li>
            <li>当社が提供する本サービス、現状有姿のまま提供されるものとします。当社は、以下の事項を含む、いかなる事項に関して保証するものでもありません。
              <ol class="pg-rules-listnumber">
                <li>本サービスが中断しないこと又はエラーがないこと。</li>
                <li>本サービスの操作若しくは通信に関するエラー、省略、中断、消去、欠陥、運営や送信の遅延、通信回線障害、投稿情報の破損のないことや不正アクセス若しくは改ざんが行われないこと。 </li>
              </ol>
            </li>
            <li>当社は、利用者のデータに漏洩又は消失の事故が発生した場合には、直ちに利用者に通知し、原因究明及び再発防止の措置を行うものとします。ただし、本項の規定は、利用者の責により発生した事故、利用者のパスワードが漏洩したことによる事故、及び利用者がご利用のパソコンやネットワークやプロバイダ等の設備に原因がある事故の場合には、適用されないものとします。 </li>
          </ol>

          <div className='form-title'>第15条 （第三者との紛争）</div>
          <ol class="pg-rules-listnumber">
            <li>利用者と第三者との間で紛争が起こった場合、紛争の当事者である利用者の責任と費用をもって当該紛争を解決するものとします。 </li>
            <li>当該紛争により又はこれに関連して当社、他の利用者及び第三者が損害を被った場合には、当該利用者がこれを賠償するものとします。</li>
            <li>利用者が投稿又は編集した投稿情報に起因又はこれに関連して生じた全ての請求について、利用者の責任と費用負担で解決するものとします。 </li>
            <li>利用者が第三者の権利を侵害したとして、第三者からのクレームや請求に関して当社に賠償金、その他の費用が発生した場合、紛争の当事者である当該利用者が、当該賠償金その他の一切の費用等（間接損害、特別損害、弁護士費用を含みますがこれに限りません。）を負担するものとします。  </li>
          </ol>

          <div className='form-title'>第16条 （反社会的勢力の排除）</div>
          <ol class="pg-rules-listnumber">
            <li>利用者は、自己又は自己の役員が、暴力団、暴力団関係企業、総会屋若しくはこれらに準ずる者又はその構成員（以下これらを「反社会的勢力」といいます。）に該当しないこと、及び次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを相互に確約します。
              <ol class="pg-rules-listnumber">
                <li> 反社会的勢力に自己の名義を利用させること</li>
                <li>反社会的勢力が経営に実質的に支配していると認められる関係を有すること </li>
              </ol>
            </li>
            <li>利用者は、自ら又は第三者を利用して、暴力的な要求行為、法的責任を超えた不当要求行為、取引に関して脅迫的な言動や暴力を用いる行為、風説・偽計・威力を用いて当社の信用を棄損し又は当社の業務を妨害する行為、反社会的勢力の活動を助長し又はその運営に資する行為、反社会的勢力への利益供与等その他これらに準ずる行為を行わないことを確約します。 </li>
            <li>当社は、利用者が前２項の一つにでも違反することが判明したときは、何らの催告を要せず、本サービス利用契約を解除することができます。 </li>
            <li>本条の規定により本サービス利用契約が解除された場合には、利用者は、解除により生じる損害について、当社に対して一切の請求を行うことができません。 </li>
          </ol>

          <div className='form-title'>第17条 （規約及びその他の利用規約等の有効性）</div>
          <ol class="pg-rules-listnumber">
            <li>本規約及びその他の利用規約等の規定の一部が法令に基づいて無効と判断されても、　本規約及びその他の利用規約等のその他の規定は有効とします。 </li>
            <li>本規約及びその他の利用規約等の規定の一部がある利用者との関係で無効とされ、又は取り消された場合でも、本規約及びその他の利用規約等はその他の利用者との関係では有効とします。  </li>
          </ol>
        </div >

      </div >
    </section >
  )
}
