import { useEffect } from "react";

export function PavingModal({ toggleShowPavingModal }) {
  useEffect(() => {}, []);

  return (
    <section className="tearms">
      <div className="bg" onClick={toggleShowPavingModal}></div>
      <div className="container-paving">
        <div className="form-paving">
          <div className="div-7">
            <div className="div-8">東洋舗材</div>
            <div className="div-8">
              <a href="tel:0339310711">(03-3931-0711)</a>
            </div>
            <a href="tel:0339310711" className="div-91">発信する</a>
            <div onClick={toggleShowPavingModal} className="div-92">キャンセル</div>
          </div>
        </div>
      </div>
    </section>
  );
}
