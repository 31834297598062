import React from 'react';
import { useEffect } from "react";
import { useLocation } from 'react-router-dom'

export function Iframe() {
    const location = useLocation();
    let link = location?.search.split("?link=")[1];

    const handleIfrmeLoad = () => {
        console.log("iframe loaded")
    }
    useEffect(() => {

    }, []);

    return (
        <section className="iframe">
            <iframe
                src={link}
                height="95%"
                width="95%"
                title="Toyohozai"
                onload={handleIfrmeLoad}
            />
        </section>
    )
}

