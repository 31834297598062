import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { postReducer } from './reducers/postReducer'
import { userReducer } from './reducers/userReducer'
import { toastReducer } from './reducers/toastReducer'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers({
  postModule: postReducer,
  userModule: userReducer,
  toastModule: toastReducer
})

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
)
