import "react-toastify/dist/ReactToastify.css";
import leftchevron from "../assets/imgs/left-chevron.png";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  signup,
  getCreatedinUser,
  sendToEmail,
} from "../store/actions/userActions";

import { useHistory, useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import { ToastContainer } from "react-toastify";
import { toastService } from "../services/toastService";
import loadingGif from "../assets/imgs/loading-gif.gif";
import logow from "../assets/imgs/weblogo.png";

export const Register = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { createdInUser } = useSelector((state) => state.userModule);

  const [option, setOption] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [regis, setRegis] = useState(false);
  const [expried, setExpried] = useState(false);

  const [msg0, setMsg0] = useState("");
  const [msg1, setMsg1] = useState("");
  const [msg2, setMsg2] = useState("");
  const [msg3, setMsg3] = useState("");

  const showMsg0 = (txt) => {
    setMsg0(txt);
    // setTimeout(() => setMsg0(""), 3000);
  };
  const showMsg1 = (txt) => {
    setMsg1(txt);
    // setTimeout(() => setMsg1(""), 3000);
  };
  const showMsg2 = (txt) => {
    setMsg2(txt);
    // setTimeout(() => setMsg2(""), 3000);
  };
  const showMsg3 = (txt) => {
    setMsg3(txt);
    // setTimeout(() => setMsg3(""), 3000);
  };
  const [cred, setCred] = useState({
    email: "",
    password: "",
    repassword: "",
    name: "",
    company_working_at_name: "",
    company_working_at_phone_number: "",
    gender: 0,
    age: 0,
  });

  const handleChange = async ({ target }) => {
    const field = target.name;
    let value = target.type === "number" ? +target.value || "" : target.value;
    setCred((prevCred) => ({ ...prevCred, [field]: value }));
  };

  const doSubmit = async () => {
    let em = decryptAES(
      location?.search.split("?email=")[1].split("&datetime=")[0]
    ); //get_email
    let dt = decryptAES(location?.search.split("&datetime=")[1]); // get_datetime
    if (em !== "" && dt !== "") {
      checkExpried(em);
    } else {
      return;
    }
    if (cred.name === ""){
      showMsg0('このフィールドを記入してください ...');
      return;
    }
    if (cred.password === ""){
      showMsg1('このフィールドを記入してください ...');
      return;
    } else if (cred.password !== "" && !validate_input_password(cred.password)) {
      showMsg1('入力されたパスワードは正しい形式ではありません ...');
      return;
    }
    if (cred.password !== cred.repassword) {
      showMsg2('入力されたパスワードは同じではありません ...');
      return;
    }
    if (cred.company_working_at_phone_number !== "" && !cred.company_working_at_phone_number.match("[0-9]{10}")) {
      showMsg3("有効な企業電話番号（英数字のみ）を入力してください");
      return;
    }
    dispatch(signup(cred)).then((user1) => {
      if (user1 !== null && user1.status !== 200) {
        toastService.notifyError("処理中にエラーが発生しました");
      }
    });
  };

  const onRadioChange = (e) => {
    let selected;
    switch (e.target.value) {
      case "3":
        selected = 3;
        break;
      case "2":
        selected = 2;
        break;
      case "1":
        selected = 1;
        break;
      case "0":
        selected = 0;
        break;
      default:
        selected = null;
    }
    setOption(selected);
    cred.gender = selected;
  };

  function decryptAES(encryptedBase64) {
    var key = process.env.REACT_APP_KEY;
    return CryptoJS.AES.decrypt(encryptedBase64, key).toString(
      CryptoJS.enc.Utf8
    );
  }

  function checkExpried(email) {
    cred.email = email;
    let current = new Date().getTime();
    let datetime = Number(decryptAES(location?.search.split("&datetime=")[1]));
    // console.log('current >>> ' + current)
    // console.log('datetime >>> ' + datetime)
    // if (current - datetime > 60 * 60) {
    if (current - datetime > 24 * 60 * 60 * 1000) {
      setExpried(true);
    }
  }

  function validate_input_password(password) {
    let check = /(?=.*[a-z])(?=.*[0-9])(?=.{8,})/;
    return password.match(check);
  }

  const goback = async () => {
    history.push("/signup");
  };

  useEffect(() => {
    if (JSON.stringify(createdInUser) === null || createdInUser === null) {
      if (location.search === null || location.search === "") {
        history.push("/");
        return;
      }
      let em = decryptAES(
        location?.search.split("?email=")[1].split("&datetime=")[0]
      ); //get_email
      let dt = decryptAES(location?.search.split("&datetime=")[1]); // get_datetime

      if (em === "" || dt === "") {
        history.push("/signin");
        return;
      }
      if (em !== "") {
        dispatch(sendToEmail({ to: em }, 0)).then((res) => {
          // console.log(res.data.length);
          if (res.data.length > 0) {
            setRegis(true);
          } else {
            checkExpried(em);
          }
        });
      }
    } else {
      let em = decryptAES(
        location?.search.split("?email=")[1].split("&datetime=")[0]
      ); //get_email
      if (em !== "" && em != null) {
        dispatch(getCreatedinUser(em)).then((eme) => {
          if (eme) {
            console.log("eme >>> " + eme);
          }
        });
      }
    }
  }, [dispatch]);

  if (spinner) {
    return (
      <section className="network">
        <span className="gif-container">
          <img className="loading-gif" src={loadingGif} alt="ok" />
        </span>
      </section>
    );
  }
  if (regis) {
    return (
      <div className="register-page">
        <div className="div">
          <div className="div-2">
            <div className="div-5"><img className="logow" src={logow} alt="Toyohozai" /></div>
          </div>
          <div className="div-6">
            <div className="div-7"></div>

            <div className="div-13">
              <div className="div-131"></div>
            </div>
            <div className="div-18">
              <span>こちらのメールアドレスが登録済みです</span>
            </div>
            <div className="div-13"></div>
            <div className="div-13"></div>

            <div className="div-8">
              <a onClick={() => history.push("/signin")}>サインインへ戻る</a>
            </div>

            <div className="div-8"></div>
            <div className="div-18"></div>
            <div className="div-13"></div>
          </div>
        </div>
      </div>
    );
  } else if (expried) {
    return (
      <div className="register-page">
        <div className="div">
          <div className="div-2">
            <div className="div-5"><img className="logow" src={logow} alt="Toyohozai" /></div>
          </div>
          <div className="div-6">
            <div className="div-7"></div>

            <div className="div-13">
              <div className="div-131"></div>
            </div>
            <div className="div-18">
              <span>登録情報の有効期限が切れています</span>
            </div>
            <div className="div-13"></div>
            <div className="div-13"></div>

            <div className="div-8">
              <a onClick={() => history.push("/signup")}>登録に戻る</a>
            </div>

            <div className="div-8"></div>
            <div className="div-18"></div>
            <div className="div-13"></div>
          </div>
        </div>
      </div>
    );
  } else if (JSON.stringify(createdInUser) === null || createdInUser === null) {
    return (
      <div className="register-page">
        <ToastContainer />
        <div className="div">
          <div className="div-2">
            <div className="div-5"><img className="logow" src={logow} alt="Toyohozai" /></div>
            <div className="div-55">
              <img src={leftchevron} alt="Toyohozai" className="div-55-img4" onClick={goback} />
            </div>
          </div>
          <div className="div-6">
            <div className="div-7">ユーザー情報登録</div>
            <form
              onSubmit={(ev) => {
                ev.preventDefault();
                doSubmit();
              }}
            >
              <div className="div-8">
                <div className="div-9">ユーザー名（姓名）</div>
                <input
                  className="div-10"
                  onChange={handleChange}
                  type="text"
                  id="name"
                  name="name"
                  value={cred.name}
                  placeholder="東洋 太郎"
                />
                <div className="msg">
                  <p className="msg">{msg0}</p>
                </div>
                <div className="div-11">パスワード</div>
                <input
                  className="div-10"
                  onChange={handleChange}
                  type="text"
                  id="password"
                  name="password"
                  value={cred.password}
                  placeholder="半角英数字、記号の組み合わせ8文字以上"
                />
                <div className="msg">
                  <p className="msg">{msg1}</p>
                </div>
                <div className="div-11">パスワード（確認用）</div>
                <input
                  className="div-10"
                  onChange={handleChange}
                  type="text"
                  id="repassword"
                  name="repassword"
                  value={cred.repassword}
                  placeholder=""
                />
                <div className="msg">
                  <p className="msg">{msg2}</p>
                </div>
                <div className="div-11">
                  <span>在籍企業名</span>
                </div>
                <div className="div-151">
                  <span>在籍有無を確認させていただく場合がございます</span>
                </div>
                <input
                  className="div-10"
                  onChange={handleChange}
                  type="text"
                  id="company_working_at_name"
                  name="company_working_at_name"
                  value={cred.company_working_at_name}
                  placeholder="東洋舗材株式会社"
                />
                <div className="div-15">
                  <span>在籍企業電話番号</span>
                </div>
                <div className="div-151">
                  <span>お電話を差し上げる場合がございます</span>
                </div>
                <input
                  className="div-10"
                  onChange={handleChange}
                  type="text"
                  id="company_working_at_phone_number"
                  name="company_working_at_phone_number"
                  value={cred.company_working_at_phone_number}
                  placeholder="在籍企業電話番号"
                />
                <div className="msg">
                  <p className="msg">{msg3}</p>
                </div>
                <div className="div-19">性別</div>
                <div className="div-20">
                  <input
                    type="radio"
                    id="gender"
                    name="gender"
                    value={2}
                    checked={option === 2}
                    onChange={onRadioChange}
                  />
                  <label htmlFor="男性" className={option === 2 ? "男性" : ""}>
                    男性
                  </label>
                  <input
                    type="radio"
                    id="gender"
                    name="gender"
                    value={3}
                    checked={option === 3}
                    onChange={onRadioChange}
                  />
                  <label htmlFor="女性" className={option === 3 ? "女性" : ""}>
                    女性
                  </label>
                  <input
                    type="radio"
                    id="gender"
                    name="gender"
                    value={1}
                    checked={option === 1}
                    onChange={onRadioChange}
                  />
                  <label
                    htmlFor="その他"
                    className={option === 1 ? "その他" : ""}
                  >
                    その他
                  </label>
                  <input
                    type="radio"
                    id="gender"
                    name="gender"
                    value={0}
                    checked={option === 0}
                    onChange={onRadioChange}
                  />
                  <label
                    htmlFor="回答しない"
                    className={option === 0 ? "回答しない" : ""}
                  >
                    回答しない
                  </label>
                </div>

                <div className="div-35">年齢</div>
                <select
                  className="div-36"
                  value={cred.age}
                  name="age"
                  id="age"
                  onChange={handleChange}
                >
                  <option value={0} disabled hidden>
                    未回答
                  </option>
                  <option value={1}>10-19歳</option>
                  <option value={2}>20-29歳</option>
                  <option value={3}>30-39歳</option>
                  <option value={4}>40-49歳</option>
                  <option value={5}>50-59歳</option>
                  <option value={6}>60-69歳</option>
                  <option value={7}>70-79歳</option>
                </select>
                <br></br>
                <button
                  disabled={
                    !cred.password &&
                    !cred.repassword &&
                    !cred.name &&
                    !cred.company_working_at_name &&
                    !cred.company_working_at_phone_number
                  }
                  className={
                    "div-38 sign-in-btn " +
                    (cred.password ||
                      cred.repassword ||
                      cred.name ||
                      cred.company_working_at_name ||
                      cred.company_working_at_phone_number
                      ? ""
                      : "btn-active")
                  }
                >
                  新規登録
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="register-page">
        <ToastContainer />
        <div className="div">
          <div className="div-2">
            <div className="div-5"><img className="logow" src={logow} alt="Toyohozai" /></div>
          </div>
          <div className="div-6">
            <div className="div-7">ユーザー情報登録</div>

            <div className="div-13">
              <div className="div-131"></div>
            </div>

            <div className="div-18">
              <span>ユーザー情報の登録が完了しました。</span>
            </div>
            <div className="div-13">
              弊社で審査を実施しますので
              <br></br>
              利用開始までしばらくお待ちください
            </div>

            <div className="div-13">
              通常２〜３営業日ほどで利用開始の
              <br></br>
              ご連絡を差し上げております。
              <br></br>
              連絡が届かない場合はお手数ですが
              <br></br>
              03-3931-0711までご連絡ください。
            </div>

            <div className="div-9">
              <br />
            </div>
            <button
              onClick={() => history.push("/signin")}
              className="sign-in-btn"
            >
              {"サインインへ戻る"}
            </button>

            <div className="div-9">
              <br /> <br />
            </div>
            <div className="div-9"></div>
            <br />
            <div className="div-18"></div>
            <div className="div-9"></div>
            <div className="div-13"></div>
          </div>
        </div>
      </div>
    );
  }
};
