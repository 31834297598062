import { useState } from "react";
import { useDispatch } from "react-redux";
import { sendToEmail } from "../store/actions/userActions";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastService } from "../services/toastService";
import loadingGif from "../assets/imgs/loading-gif.gif";
import logow from "../assets/imgs/weblogo.png";

export const Forgot = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [spinner, setSpinner] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const [cred, setCred] = useState({
    to: "",
  });

  const handleChange = async ({ target }) => {
    const field = target.name;
    let value = target.type === "number" ? +target.value || "" : target.value;
    const em = document.getElementById("to");
    if (value !== null) {
      if (!value.match(/.+@.+/)) {
        em.setCustomValidity(
          "メールアドレスが無効です"
        );
        setIsEmailValid(false);
      } else {
        em.setCustomValidity("");
        setIsEmailValid(true);
      }
    }
    setCred((prevCred) => ({ ...prevCred, [field]: value }));
  };

  const doSubmit = async () => {
    setSpinner(false);
    dispatch(sendToEmail(cred, 2)).then((em) => {
      if (em.status === 500) {
        setSpinner(true);
        toastService.notifyError("会員登録のないメールアドレスです");
        history.push("/forgot");
      } else if (em.status === 200) {
        setSpinner(true);
        toastService.notifySuccess("パスワード再発行のメールが送信されました");
        history.push("/signin");
      }
    });
  };
  if (!spinner) {
    return (
      <section className="network">
        <span className="gif-container">
          <img className="loading-gif" src={loadingGif} alt="ok" />
        </span>
      </section>
    );
  }
  return (
    <div className="forgot-page">
      <ToastContainer />
      <div className="div">
        <div className="div-2">
          <div className="div-5"><img className="logow" src={logow} alt="Toyohozai" /></div>
        </div>
        <div className="div-60">
          <div className="div-7">パスワード再設定</div>
          <form
            onSubmit={(ev) => {
              ev.preventDefault();
              doSubmit();
            }}
          >
            <div className="div-8">
              <div className="div-9">メールアドレス</div>
              <input
                className="div-10"
                onChange={handleChange}
                type="email"
                id="to"
                name="to"
                value={cred.to}
                placeholder="メールアドレス"
              />
            </div>
            {/* <div className="msg"><p className="msg">{msg}</p></div> */}
            <div className="div-9">
              <br /> <br />
            </div>
            <div className="div-18">
              <span>
                ご登録されているメールアドレス宛に仮パスワードを送信いたします。
              </span>
            </div>
            <div className="div-18">
              <span>
                メールアドレスをお忘れの場合は、お手数ですが再度会員登録をお願い致します。
              </span>
            </div>
            <br></br>
            <br></br>
            <button
              disabled={!isEmailValid}
              className={`${isEmailValid ? "sign-in-btn" : "sign-in-btn btn-active"}`}
            >
              送信する
            </button>
          </form>
          <div className="div-9">
          </div>
          <div className="div-90">
            <div className="div-91" />
          </div>
          <div className="div-18">
            <span>会員登録がお済みの方は</span>
            <Link to="/signin">
              <span
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                サインイン
              </span>
            </Link>
          </div>
          <div className="div-9"></div>
          <div className="div-13"></div>
        </div>
      </div>
    </div>
  );
};
