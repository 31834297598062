import { httpService } from '../httpService'
import jwt_decode  from 'jwt-decode'
const STORAGE_KEY_LOGGEDIN_MEMBER = 'user'
const STORAGE_KEY_LOGGEDIN_GUEST = 'guest'

export const userService = {
  login,
  logout,
  clearEmail,
  signup,
  updateUser,
  deactivedUser,
  searchSupplier,
  searchHistories,
  getSupplierById,
  insertHistory,
  insertUsersAccessLog,
  getSupplier,
  bigCategory,
  mediumCategory,
  detailCategory,
  postSupplier,
  getNoti,
  getLoginUser,
  getCreatedinUser,
  sendToEmail,
  checkExpried
}

async function login(userCred) {
  const user = await httpService.post('authenticate', userCred)
  if (user !== null && user.status === 200) {
    _saveLocalStorage(STORAGE_KEY_LOGGEDIN_MEMBER, user);
    insertUsersAccessLog(user);
    return _saveLocalStorage(STORAGE_KEY_LOGGEDIN_MEMBER, user);
  }
  return user;
}

async function sendToEmail(emailCred, type) {
  if (type === 0) {
    return await httpService.post('check-email', emailCred)
  } else if (type === 1) {
    return await httpService.post('send-ok-email', emailCred)
  } else if (type === 2) {
    return await httpService.post('send-reissue-password-email', emailCred)
  } else if (type === 3) {
    return await httpService.post('send-account-registration-email', emailCred)
  } else {
    return null;
  }
}

async function searchSupplier(body) {
  return await httpService.post('search', body, null)
}

async function getSupplier() {
  return await httpService.get('suppliers', null)
}

async function getSupplierById(supId) {
  return await httpService.get(`suppliers/${supId}`, null)
}

async function bigCategory() {
  return await httpService.get('big_Category', null)
}

async function mediumCategory(bigId) {
  return await httpService.get(`medium_category/${bigId}`, null)
}

async function detailCategory(bigId, medId) {
  return await httpService.get(`detail_category/${bigId}/${medId}`, null)
}

async function searchHistories(userId) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  return await httpService.get(`searchHistories/${userId}`, null, token)
}

async function insertHistory(body) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  return await httpService.post(`insertHistory`, body, token)
}

async function insertUsersAccessLog(user) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  let body = {
    user_id: '100' + user.data.id,
    username: user.data.name,
    company_name: user.data.company_working_at_name,
    logindate: new Date()
  };
  return await httpService.post(`insertUsersAccessLog`, body, token)
}

async function getNoti(body) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  return await httpService.post('getNoti', body, token)
}

async function postSupplier(body) {
  return await httpService.post('nearby', body, null)
}

async function deactivedUser(userId) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  let usernew = await httpService.put(`deactived/${userId}`, null, token)
  localStorage.removeItem(STORAGE_KEY_LOGGEDIN_MEMBER)
  sessionStorage.removeItem(STORAGE_KEY_LOGGEDIN_MEMBER)
  return usernew;
}

async function updateUser(userId, userCred) {
  let loginU = getLoginUser();
  if (loginU === null) {
    return null;
  }
  let token = 'Bearer ' + loginU.token;
  let usernew = await httpService.put(`update/${userId}`, userCred, token)
  let userold = JSON.parse(localStorage.getItem(STORAGE_KEY_LOGGEDIN_MEMBER) || 'null')
  userold.status = usernew.status
  if (usernew.data.hasOwnProperty("email")) {
    userold.data.email = usernew.data.email
  }
  if (usernew.data.hasOwnProperty("password_hash")) {
    userold.data.password_hash = usernew.data.password_hash
  }
  if (usernew.data.hasOwnProperty("gender")) {
    userold.data.gender = usernew.data.gender
  }
  if (usernew.data.hasOwnProperty("age")) {
    userold.data.age = usernew.data.age
  }
  return _saveLocalStorage(STORAGE_KEY_LOGGEDIN_MEMBER, userold)
}

async function signup(userCred) {
  const user1 = await httpService.post('register', userCred)
  if (user1 !== null && user1.status === 200) {
    return _saveLocalStorage(userCred.email, user1)
  }
  return user1;
}

async function logout() {
  localStorage.removeItem(STORAGE_KEY_LOGGEDIN_MEMBER)
  sessionStorage.removeItem(STORAGE_KEY_LOGGEDIN_MEMBER)
  localStorage.removeItem(STORAGE_KEY_LOGGEDIN_GUEST)
  sessionStorage.removeItem(STORAGE_KEY_LOGGEDIN_GUEST)
}

async function clearEmail() {
  localStorage.clear()
  sessionStorage.clear()
}

function _saveLocalStorage(key, user) {
  localStorage.setItem(key, JSON.stringify(user))
  return user
}

function getLoginUser() {
  return JSON.parse(localStorage.getItem(STORAGE_KEY_LOGGEDIN_MEMBER) || 'null')
}

function checkExpried(userLogin) {
  if (userLogin === null) {
    return false
  }
  let decodedToken = jwt_decode(JSON.stringify(userLogin.token));
  let currentDate = new Date();
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    return true;
  } else {
    return false;
  }
}

function getCreatedinUser(email) {
  return JSON.parse(localStorage.getItem(email) || 'null')
}