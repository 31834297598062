import Axios from 'axios'

const USER_API = process.env.REACT_APP_API_URL + 'user/'

var axios = Axios.create({
  withCredentials: true,
})

export const httpService = {
  get(endpoint, data, bearer) {
    return ajax(endpoint, 'GET', data, bearer)
  },
  post(endpoint, data, bearer) {
    return ajax(endpoint, 'POST', data, bearer)
  },
  put(endpoint, data, bearer) {
    return ajax(endpoint, 'PUT', data, bearer)
  },
  delete(endpoint, data, bearer) {
    return ajax(endpoint, 'DELETE', data, bearer)
  },
}

async function ajax(endpoint, method = 'GET', data = null, bearer) {
  try {
    const res = await axios({
      url: `${USER_API}${endpoint}`,
      method,
      headers: {
        Authorization: `${bearer}`
      },
      data,
      params: method === 'GET' ? data : null,
    })
    return res.data
  } catch (err) {
    console.dir(err)
    throw err
  }
}
