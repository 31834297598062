import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  sendToEmail,
  setLoginGuest,
  clearEmail,
  logout,
} from "../store/actions/userActions";
import { Link, useHistory } from "react-router-dom";
import { TearmModal } from "../cmps/terms/TearmModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastService } from "../services/toastService";
import logow from "../assets/imgs/weblogo.png";

export const Signup = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [register] = useState(true);
  const [cred, setCred] = useState({
    to: "",
  });

  // const [msg, setMsg] = useState('')
  const [hidden, setHidden] = useState(false);
  const [isShowTearmModal, setIsShowTearmModal] = useState(false);
  // const [isCheckboxValid, setIsCheckboxValid] = useState(true);
  const toggleShowTearmModal = () => {
    const checkbox = document.getElementById("termsCheckbox");
    if (!checkbox.checked) {
      checkbox.setCustomValidity(
        "利用規約に同意する場合は、チェックボックスをオンにしてください"
      );
      // setIsCheckboxValid(false);
      return;
    } else {
      checkbox.setCustomValidity("");
      // setIsCheckboxValid(true);
      setIsShowTearmModal((prev) => !prev);
    }
  };

  const handleChange = async ({ target }) => {
    const field = target.name;
    let value = target.type === "number" ? +target.value || "" : target.value;
    const em = document.getElementById("to");
    if (value !== null) {
      if (!value.match(/.+@.+/)) {
        em.setCustomValidity(
          "メールアドレスが無効です"
        );
      } else {
        em.setCustomValidity("");
      }
    }
    setCred((prevCred) => ({ ...prevCred, [field]: value }));
  };

  const doSubmit = () => {
    const checkbox = document.getElementById("termsCheckbox");
    if (!checkbox.checked) {
      checkbox.setCustomValidity(
        "利用規約に同意する場合は、チェックボックスをオンにしてください。"
      );
      // setIsCheckboxValid(false);
      return;
    }

    if (register) {
      dispatch(sendToEmail(cred, 3)).then((em) => {
        if (em.status === 500) {
          toastService.notifyError(
            "このメールアドレスはすでに登録されています"
          );
        } else if (em.status === 200) {
          setHidden(true);
        }
      });
    } else {
      history.push("/");
    }
  };

  const goback = async () => {
    history.replace("/");
    setTimeout(() => {
      history.replace("/signup");
    }, 10);
  };

  useEffect(() => {
    dispatch(setLoginGuest(false));
    dispatch(clearEmail());
    dispatch(logout());
  }, [dispatch]);

  if (!hidden) {
    return (
      <div className="forgot-page">
        <ToastContainer />
        <div className="div">
          <div className="div-2">
            <div className="div-5"><img className="logow" src={logow} alt="Toyohozai" /></div>
          </div>
          <div className="div-60">
            <div className="div-7">会員登録</div>
            <form
              onSubmit={(ev) => {
                ev.preventDefault();
                doSubmit();
              }}
            >
              <div className="div-8">
                <div className="div-9">メールアドレス</div>
                <input
                  className="div-10"
                  onChange={handleChange}
                  type="email"
                  id="to"
                  name="to"
                  value={cred.to}
                  placeholder="メールアドレス"
                />
              </div>
              {/* <div className="msg"><p className="msg">{msg}</p></div> */}
              <div className="div-9">
                <br /> <br />
              </div>
              <div className="div-18">
                <div className="div-181">
                  <input
                    className="input-ckb"
                    id="termsCheckbox"
                    type="checkbox"
                    onClick={toggleShowTearmModal}
                  />
                  <p>利用規約をご参照の上、ご登録ください</p>
                </div>
              </div>
              <button
                disabled={!cred.to}
                className={
                  "sign-in-btn " + (cred.to === "" ? "btn-active" : "")
                }
              >
                新規登録
              </button>
            </form>
            <div className="div-9">
            </div>
            <div className="div-90">
              <div className="div-91" />
            </div>
            <div className="div-18">
              <span>会員登録がお済みの方は</span>
              <Link to="/signin">
                <span
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  サインイン
                </span>
              </Link>
            </div>
            <div className="div-9"></div>
            <div className="div-13"></div>
          </div>
        </div>
        {isShowTearmModal && (
          <TearmModal toggleShowTearmModal={toggleShowTearmModal} />
        )}
      </div>
    );
  } else {
    return (
      <div className="forgot-page">
        <ToastContainer />
        <div className="div">
          <div className="div-2">
            <div className="div-5"><img className="logow" src={logow} alt="Toyohozai" /></div>
          </div>
          <div className="div-60">
            <div className="div-7">会員登録</div>
            <div className="div-9"></div>
            <div className="div-13">
              {cred.to}
              <br></br>
              にメールを送信しました
            </div>
            <div className="div-13">
              メールに記載されたURLから
              <br></br>
              アカウント登録をしてください
            </div>

            <div className="div-13">
              URLは24時間有効です
              <br></br>
              ＠toyohozai.com からの受信設定を許諾してください
            </div>
            <div className="div-6091" />
            <div className="div-6092" />
            <div className="div-13">
              URLが期限切れの場合は
              <br></br>
              お手数ですが{" "}
              <span
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={goback}
              >
                メールアドレス送信
              </span>
              から
              <br></br>
              やり直してください
            </div>
            <div className="div-13"></div>
          </div>
        </div>
      </div>
    );
  }
};
