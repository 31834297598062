import { useEffect } from "react";
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setLoginGuest, clearEmail, logout } from '../../store/actions/userActions'

export function GuestModal({ toggleShowGuestModal }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const doSignup = async () => {
    dispatch(setLoginGuest(false));
    dispatch(clearEmail());
    dispatch(logout());
    history.push(`/signup`)
  }

  const doLogout = async () => {
    dispatch(setLoginGuest(false));
    dispatch(clearEmail());
    dispatch(logout());
    history.push(`/signin`);
  };
  
  useEffect(() => { }, []);

  return (
    <section className="tearms">
      <div className="bg" onClick={toggleShowGuestModal}></div>
      <div className="container-paving">
        <div className="form-paving">
          <div className="div-7">
            <div className="div-8">こちらは会員限定機能となります。</div>
            <div className="div-91" onClick={doSignup}>会員登録する</div>
            <div className="div-92" onClick={doLogout}>キャンセル</div>
          </div>
        </div>
      </div>
    </section>
  );
}
