import { lazy, Suspense, useEffect } from "react";
import { Switch, useHistory, useLocation } from 'react-router-dom'
import { Footer } from "../cmps/footer/Footer";
import { Iframe } from "./Iframe";

import { userService } from '../services/user/userService'
import { Offline} from "react-detect-offline";
import { MapShare } from "./MapShare";
import PrivateRoute from "../cmps/PrivateRoute";

const Map = lazy(() => import("./Map"));
const Paving = lazy(() => import("./Paving"));
const Setting = lazy(() => import("./Setting"));
const EditProfile = lazy(() => import("./EditProfile"));
const MapList = lazy(() => import("./MapList"));
const ListSupplier = lazy(() => import("./ListSupplier"));
const History = lazy(() => import("./History"));
const Main = lazy(() => import("./Main"));
const NotFound = lazy(() => import("./Notfound"));
const PdfViewer = lazy(() => import("./PdfViewer"));
const Notifications = lazy(() => import("./Notifications"));

export function Home() {
  const history = useHistory();
  const location = useLocation();
  const isAuthenticated = userService.getLoginUser();

  useEffect(() => {
    if (location.pathname === "/home/share") {
      history.push("/home/share" + location.search);
    } else if (isAuthenticated && location.pathname !== "/home/share") {
      history.replace("/");
      setTimeout(() => {
        history.replace("/home/main");
      }, 10);
    }
  }, [history])

  return (
    <>
      <div className="home-page">
        <Suspense>
          <Switch>
            <PrivateRoute path="/home/share" component={MapShare}/>
            <PrivateRoute path="/home/supplier" component={Iframe}/>
            <PrivateRoute path="/home/viewpdf" component={PdfViewer} />
            <PrivateRoute path="/home/main" component={Main} />
            <PrivateRoute path="/home/paving" component={Paving} />
            <PrivateRoute path="/home/map" component={Map} />
            <PrivateRoute path="/home/list" component={MapList} />
            <PrivateRoute path="/home/listsup" component={ListSupplier} />
            <PrivateRoute path="/home/setting" component={Setting} />
            <PrivateRoute path="/home/editp" component={EditProfile} />
            <PrivateRoute path="/home/history" component={History} />
            <PrivateRoute path="/home/notification" component={Notifications} />
            <PrivateRoute path="*/*" component={NotFound} />
          </Switch>
        </Suspense>
      </div>
      <Offline>
        <div className="detect">
          <span>おっと、オフラインです</span> 
        </div>
      </Offline>
      {location.pathname !== "/home/share" && <Footer />}
      {/* <Footer /> */}
    </>
  );
}
