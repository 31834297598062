const INITIAL_STATE = {
  updateUser: null,
  searchSupplier: null,
  getSupplierById: null,
  postSupplier: null,
  bigCategory: null,
  getNoti: null,
  currentLocation: null,
  createdInUser: null,
  loggedInUser: null,
  loggedInGuest: false,
  getHistories: null
}

export function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'BIG_CATEGORY':
      return {
        ...state,
        bigCategory: action.bigc,
      }
    case 'CURRENT_LOCATION':
      return {
        ...state,
        currentLocation: action.current,
      }
    case 'GET_HISTORY':
      return {
        ...state,
        getHistories: action.his,
      }
    case 'GET_SUPPLIER':
      return {
        ...state,
        getSupplierById: action.supp,
      }
    case 'SEARCH_SUPPLIER':
      return {
        ...state,
        searchSupplier: action.supplier,
      }
    case 'POST_SUPPLIER':
      return {
        ...state,
        postSupplier: action.suppliers,
      }
    case 'GET_NOTI':
      return {
        ...state,
        getNoti: action.noti,
      }
    case 'LOGIN_USER':
      return {
        ...state,
        loggedInUser: action.user,
      }
    case 'UPDATE_USER':
      return {
        ...state,
        updateUser: action.userup,
      }
    case 'CREATED_IN_USER':
      return {
        ...state,
        createdInUser: action.user1,
      }
    case 'LOGGEDIN_GUEST':
      return {
        ...state,
        loggedInGuest: action.bool,
      }
    case 'SIGNUP':
      return {
        ...state,
        createdInUser: action.user1,
      }
    case 'LOGOUT':
      return {
        ...state,
        loggedInUser: null,
      }
    default:
      return state
  }
}
